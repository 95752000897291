// config.js
let API_URL;

if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://geomaze-api.onrender.com/api';
} else {
  API_URL = 'http://localhost:3001/api';
}

export default {
  API_URL
};
