import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {  Paper, Grid, TextField, Button, Typography, IconButton, Divider } from '@mui/material';
import { Apple, Google } from '@mui/icons-material'; 
import styled from 'styled-components';
import config from '../../config';

const WhiteDivider = styled(Divider)`
  background-color: grey;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(120deg, #000000, #003366, #004d00);
`;

const Background = styled.div`
  height: 100vh;
  background: linear-gradient(45deg, #000000, #003366, #004d00);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoundedButton = styled(Button)`
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border: 0;
  border-radius: 25px !important;
  margin-top: 20px !important;
  padding: 10px 30px !important;
  color: white;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
`;
const StyledPaper = styled(Paper)`
  && {
    background: linear-gradient(120deg, #003366, #000000, #004d00);
    padding: 2em;
    width: 400px;
    border-radius: 60px; // Increase to make it much more rounded
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
  }
`;



function LogIn() {
  // State Management 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  
  const navigate = useNavigate();
  const { login } = useAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
  

      if (response.status === 200) {
        const data = await response.json();
        console.log(data.message);
        
        // Store the JWT in local storage
        localStorage.setItem('token', data.token);
        // If login is successful
        login(data.token); // update the authentication state
        navigate('/game'); // redirect the user to the game
      } else {
        console.error(`Error response from server: ${response.status} - ${response.statusText}`);
        const text = await response.text();
        console.error(`Server says: ${text}`);
      }
    } catch (error) {
      console.error('There was an error logging in', error);
    }
};




  return (
    <Background>
      <StyledPaper>
        <Grid container direction="column" spacing={2}>
          <Grid item>
          <Typography variant="h4" align="center" gutterBottom style={{ color: 'white' }}>
            Let's have Fun
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: 'white', zIndex: '1' }, shrink: true, position: "static" }} // Set position to static
              inputProps={{ style: { color: 'white' } }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="password"
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ style: { color: 'white', zIndex: '1' }, shrink: true, position: "static" }} // Set position to static
              inputProps={{ style: { color: 'white' } }}
            />
          </Grid>
          <Grid item>
            <RoundedButton variant="contained" color="primary" fullWidth type="submit" onClick={handleSubmit}>
              Sign in
            </RoundedButton>
          </Grid>
          <Grid item>
          <WhiteDivider /> {/* Use the styled WhiteDivider */}
          </Grid>
          <Grid item container justifyContent="center" spacing={1}>
            <Grid item>
              <Button onClick={() => {}} style={{ color: 'white' }}>Forgot password?</Button> {/* Set the text color to white */}
            </Grid>
            <Grid item>
              <Button onClick={() => navigate('/signup')} style={{ color: 'white' }}>Sign Up</Button> {/* Set the text color to white */}
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </Background>
  );
}


export default LogIn;
