import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
`;

const BackgroundContainer = styled.div`
    padding: 2rem;
    background: linear-gradient(rgba(6,2,73,0.5), rgba(61,96,229,0.5), rgba(113,231,159,0.7)), url(/geomazestreet.png);
    background-size: cover;
    color: white;
`;

const ContentContainer = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MainTextContainer = styled.div`
    margin-bottom: 2rem;
    text-align: center;
`;

const StyledGreenActionButton = styled(Button)`
    background: radial-gradient(circle, rgba(186,249,199,1) 25%, rgba(144,250,162,1) 54%, rgba(0,255,35,1) 100%);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 10px 30px;
    &:hover {
        background: radial-gradient(circle, rgba(144,250,162,1) 25%, rgba(0,255,35,1) 54%, rgba(186,249,199,1) 100%);
    }
`;

const FeaturesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 3rem;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const FeatureBox = styled.div`
    flex: 1;
    text-align: center;
    max-width: 300px;
    border: 1px solid white;
    padding: 1rem;
    border-radius: 10px;
`;

const FAQContainer = styled.div`
    width: 100%;
`;

const FAQItem = styled.div`
    border: 1px solid white;
    border-radius: 8px;
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const FAQTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
`;

function PreHome() {
    const navigate = useNavigate();

    const [openFAQ, setOpenFAQ] = React.useState(null);

const handleFAQClick = (index) => {
    if (openFAQ === index) {
        setOpenFAQ(null);
    } else {
        setOpenFAQ(index);
    }
};

    return (
        <FlexContainer>
        <BackgroundContainer>
            <ContentContainer>
                <MainTextContainer>
                    <Typography variant="h1" gutterBottom>ZOOP THE WORLD</Typography>
                    <Typography variant='body1' gutterBottom>
                        Get dropped anywhere from New York City to Florence and navigate through the maze-like streets.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        Improve your spatial memory and challenge your friends.
                    </Typography>
                    <StyledGreenActionButton onClick={() => navigate('/login')}>
                        PLAY FOR FREE
                    </StyledGreenActionButton>
                </MainTextContainer>
                
                <FeaturesContainer>
                    <FeatureBox>
                        <CardMedia image='./placeholder.png' component="img" width={200}/>
                        <Typography variant='h5' gutterBottom>Remember the cities</Typography>
                        <Typography variant='body2'>
                            Navigate at your own pace, earn a rating, and challenge your recall.
                        </Typography>
                    </FeatureBox>
                    <FeatureBox>
                        <CardMedia image='./placeholder.png' component="img" width={200}/>
                        <Typography variant='h5' gutterBottom>Compete globally</Typography>
                        <Typography variant='body2'>
                            Test your skills against players worldwide and climb the leaderboards.
                        </Typography>
                    </FeatureBox>
                </FeaturesContainer>

                <Divider sx={{ backgroundColor: 'white', width: '100%', margin: '1rem 0' }} />
                <Typography variant='h6' gutterBottom>FREQUENTLY ASKED QUESTIONS</Typography>
                {<FAQContainer>
    <FAQItem onClick={() => handleFAQClick(1)}>
        <FAQTitle>
            <Typography variant='h6'>What is GeoGuessr?</Typography>
            <ExpandMoreIcon />
        </FAQTitle>
        <Collapse in={openFAQ === 1}>
            <Typography variant='body2' padding={2}>
                GeoGuessr is a geography game, in which you are dropped somewhere in the world in a street view panorama and your mission is to find clues and guess your location on the world map.
            </Typography>
        </Collapse>
    </FAQItem>
    <FAQItem onClick={() => handleFAQClick(2)}>
        <FAQTitle>
            <Typography variant='h6'>I have trouble accessing my account.</Typography>
            <ExpandMoreIcon />
        </FAQTitle>
        <Collapse in={openFAQ === 2}>
            <Typography variant='body2' padding={2}>
                If you've forgot your password, click on "Already have an account?" in the upper right corner, and then on "Forgot your password?". Enter the email address which is connected to your account, and we'll send you an email with a link to reset your password! Don't forget to check you spam email if it may have gotten caught there.
                <br /><br />
                If you are not receiving an email you might have enabled Google/Facebook login. If so, send an email to info@geoguessr.com and we will help you further. If you've forgotten which email you signed up with, no worries! Contact us at info@geoguessr.com and we'll help you out!
            </Typography>
        </Collapse>
    </FAQItem>
    {/* More FAQ items as needed */}
</FAQContainer>}
            </ContentContainer>
        </BackgroundContainer>
        </FlexContainer>
    );
}

export default PreHome;
