import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext({
    isAuthenticated: false,
    login: (token) => {},
    logout: () => {}
});

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const token = localStorage.getItem('token');
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);
    const [isAuthLoading, setIsAuthLoading] = useState(true);

    useEffect(() => {
        setIsAuthLoading(false);  // Once checked, set loading to false
    }, []);

    const login = (token) => {
        if (token) {
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    const value = {
        isAuthenticated,
        isAuthLoading,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
