import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Box, IconButton } from '@mui/material';
import styled from 'styled-components'; 
import SidebarNavigation from '../Navigation/SidebarNavigation';
import config from '../../config';

const StyledContainer = styled.div`
  background: linear-gradient(120deg, #000000, #003366, #004d00);
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;  // Align to start so only horizontal centering
  padding-top: 50px;  // Some space at the top for the sidebar navigation button
`;

const StyledTable = styled(Table)`
  // Add table-specific styles if necessary
`;


function Leaderboard() {
  const [leaders, setLeaders] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(`${config.API_URL}/leaderboard`);
        const data = await response.json();
        setLeaders(data);
      } catch (error) {
        console.error('Error fetching leaderboard', error);
      }
    };

    fetchLeaderboard();
  }, []);

  return (
    <StyledContainer>
    <SidebarNavigation isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Leaderboard
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ width: '80%', overflow: 'hidden' }}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Average Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaders.map((leader, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{leader.email}</TableCell>
                <TableCell align="right">{leader.averageRating.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Paper>
    </StyledContainer>
  );
}

export default Leaderboard;
