import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Divider,
  Button,
  TextField,
  Box,
} from "@mui/material";
import styled from "styled-components";
import config from '../../config';
import SidebarNavigation from "../Navigation/SidebarNavigation";

const StyledContainer = styled.div`  
background: linear-gradient(120deg, #000000, #003366, #004d00);
  color: #ffffff;
  position: absolute; // <-- Changed from fixed to absolute
  top: 0;
  left: 0;
  right: 0;   // <-- Ensure right and bottom are set to 0
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.9);
`;


const StyledTypography = styled(Typography)`
  font-size: ${(props) => (props.variant === "h4" ? "2rem" : "1.5rem")};
  @media (max-width: 768px) {
    font-size: ${(props) => (props.variant === "h4" ? "1.5rem" : "1.2rem")};
  }
`;


function UserProfile() {
  const [profile, setProfile] = useState({
    username: "",
    email: "", // I've added this based on your schema
    rating: 0,
    //... other fields if you have
  });

  const [editMode, setEditMode] = useState(false);
  const [newUsername, setNewUsername] = useState("");

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("token"); // Retrieve the token

      if (!token) {
        console.error("JWT not found in local storage. Please login again.");
        return;
      }

      try {
        const response = await fetch(`${config.API_URL}/profile/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token, // Include the token in headers
          },
        });
        const data = await response.json();
        if (!response.ok) {
          const errorMessage = data.message || "Unknown error";
          throw new Error(errorMessage);
        }

        setProfile(data);
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleUpdate = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token

    if (!token) {
      console.error("JWT not found in local storage. Please login again.");
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/profile/me`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token, // Include the token in headers
        },
        body: JSON.stringify({ username: newUsername }),
      });
      const updatedProfile = await response.json();
      if (!response.ok) {
        throw new Error(updatedProfile.error || "Unknown error");
      }
      setProfile(updatedProfile);
      setEditMode(false);
    } catch (error) {
      console.error("Error updating user profile", error);
    }
  };

  return (
    <StyledContainer>
      <SidebarNavigation />
      <StyledPaper elevation={3}>
        <Box sx={{ mb: 3 }}>
          {/* Display profile details */}
          <StyledTypography variant="h4" gutterBottom>
            {profile.email}
          </StyledTypography>
          {editMode ? (
            <TextField
              label="Username"
              value={newUsername}
              fullWidth
              onChange={(e) => setNewUsername(e.target.value)}
            />
          ) : (
            <StyledTypography variant="h6" gutterBottom>
              @{profile.username}
            </StyledTypography>
          )}
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" gutterBottom>
            Rating: {profile.rating}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {editMode ? (
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={() => setEditMode(true)}
                variant="contained"
                sx={{
                  transition: "0.3s",
                  "&:hover": {
                    backgroundColor: "darkblue",
                  },
                }}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
}

export default UserProfile;