import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import StreetViewGame from './components/Game/StreetViewGame';
import Leaderboard from './components/Leaderboard/Leaderboard';
import LogIn from './components/Auth/LogIn';
import SignUp from './components/Auth/SignUp';
import { AuthProvider, useAuth } from './context/AuthContext';
import UserProfile from './components/Auth/UserProfile';
import PreHome from './components/Auth/PreHome'; 
import './App.css';

const AppContainer = styled.div`
    font-family: Arial, sans-serif;
    height: 100vh;
    background: linear-gradient(rgba(30,75,115,1), rgba(255,255,255,1));
`;

function ProtectedRoute({ children }) {
    const { isAuthenticated, isAuthLoading } = useAuth();

    if (isAuthLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? children : <Navigate to="/login" replace />;
}

function App() {
  return (
      <AuthProvider>
          <Router>
              <AppContainer>
                  <Routes>
                      <Route path="/" element={<ProtectedRoute><PreHome /></ProtectedRoute>} />
                      <Route path="/login" element={<LogIn />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/game" element={<ProtectedRoute><StreetViewGame/></ProtectedRoute>} />
                      <Route path="/leaderboard" element={<Leaderboard />} />
                      <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                  </Routes>
              </AppContainer>
          </Router>
      </AuthProvider>
  );
}

export default App;