import React, { useState, useEffect, useRef, useCallback } from "react";
// import '../styles/StreetViewGameStyling.css'
import { useNavigate } from "react-router-dom";
import config from "../../config";
import styled from "styled-components";
import { Button } from "@mui/material";
import { Leaderboard as LeaderboardIcon } from "@mui/icons-material"; // You might want to add an appropriate icon for the leaderboard
import SidebarNavigation from "../Navigation/SidebarNavigation";
import Typography from "@mui/material/Typography";
import CorrectGuessIllustration from "../../assets/correctGuess.png";
import WrongGuessIllustration from "../../assets/wrongGuess.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const RoundedButton = styled(Button)`
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  border: 0;
  border-radius: 25px !important;
  margin-top: 20px !important;
  padding: 10px 30px !important;
  color: white;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
`;

const GameText = styled.p`
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center; /* To center-align the text */
  margin: 10px 0; /* Adding some margin for separation */

  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }
`;


const Highlight = styled.span`
    color: #ff4500; // Some bright color to catch attention
    font-size: 1.5em;
    font-weight: bold;
`;

const ScoreDisplay = styled.div`
    background-color: #f4f4f4; // Light grey background
    padding: 10px 20px;
    border-radius: 8px;
    margin: 10px 0;
    text-align: center;

    > h2 {
        font-size: 2em;
        color: #2c3e50; // Dark blueish-grey
    }
`;

const GameTitle = styled.h1`
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center; /* To center-align the text */
  margin-bottom: 20px; /* Increasing bottom margin for better separation */

  @media screen and (min-width: 1200px) {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
`;

// Styling for the end screen container with the adjusted gradient
const EndScreenContainer = styled.div`
  background: linear-gradient(45deg, #000000, #003366, #004d00);
  color: #ffffff; // Default white text color for contrast
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Styling for the leaderboard button at the top right corner of the screen
const StyledLeaderboardButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffffff; // Text color for the button
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); // Slightly transparent background for better visibility
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
// Styled components
const TimerWrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1001;
  border-radius: 5px;
  background: linear-gradient(45deg, #000000, #003366, #004d00);
  color: white;
  padding: 5px;
`;

const Title = styled(Typography)`
  font-size: 24px;
`;

const Score = styled(Typography)`
  font-size: 48px;
  font-weight: bold;
`;

const MAX_TIME = 300; // This is just an example value; adjust as needed.
const SCORE_MULTIPLIER = 10; // Again, adjust based on your game's needs.
const PENALTY_MULTIPLIER = 5; // Adjust based on how much penalty you want for wrong guesses.
const heading = 165;
const pitch = 0;

// Some threshold value in meters
const ACCEPTABLE_DISTANCE_THRESHOLD = 300;

const CITIES = [
  {
    name: "New York",
    landmarks: [
      {
        name: "Statue of Liberty",
        coordinates: { lat: 40.6892, lng: -74.0445 },
      },
      { name: "Central Park", coordinates: { lat: 40.7851, lng: -73.9683 } },
      { name: "Times Square", coordinates: { lat: 40.758, lng: -73.9855 } },
      { name: "Brooklyn Bridge", coordinates: { lat: 40.7061, lng: -73.9969 } },
    ],
  },
  {
    name: "Paris",
    landmarks: [
      { name: "Eiffel Tower", coordinates: { lat: 48.8584, lng: 2.2945 } },
      { name: "Louvre Museum", coordinates: { lat: 48.8606, lng: 2.3376 } },
      {
        name: "Notre-Dame Cathedral",
        coordinates: { lat: 48.853, lng: 2.3499 },
      },
      { name: "Champs-Élysées", coordinates: { lat: 48.8728, lng: 2.3078 } },
    ],
  },
  {
    name: "Tokyo",
    landmarks: [
      { name: "Tokyo Tower", coordinates: { lat: 35.6586, lng: 139.7454 } },
      { name: "Senso-ji", coordinates: { lat: 35.7148, lng: 139.7967 } },
      {
        name: "Shibuya Crossing",
        coordinates: { lat: 35.6595, lng: 139.7004 },
      },
      { name: "Meiji Shrine", coordinates: { lat: 35.6764, lng: 139.6991 } },
    ],
  },
  {
    name: "Florence",
    landmarks: [
      {
        name: "Florence Cathedral",
        coordinates: { lat: 43.7731, lng: 11.2556 },
      },
      { name: "Ponte Vecchio", coordinates: { lat: 43.7687, lng: 11.2531 } },
      { name: "Uffizi Gallery", coordinates: { lat: 43.7687, lng: 11.2559 } },
      {
        name: "Piazza della Signoria",
        coordinates: { lat: 43.7699, lng: 11.2553 },
      },
    ],
  },
  {
    name: "Barcelona",
    landmarks: [
      { name: "Sagrada Família", coordinates: { lat: 41.4036, lng: 2.1744 } },
      { name: "Park Güell", coordinates: { lat: 41.4145, lng: 2.1528 } },
      { name: "La Rambla", coordinates: { lat: 41.3815, lng: 2.173 } },
      {
        name: "Magic Fountain of Montjuïc",
        coordinates: { lat: 41.3715, lng: 2.1515 },
      },
    ],
  },
  {
    name: "Edinburgh",
    landmarks: [
      { name: "Edinburgh Castle", coordinates: { lat: 55.9486, lng: -3.2009 } },
      { name: "Arthur's Seat", coordinates: { lat: 55.9441, lng: -3.1619 } },
      { name: "Royal Mile", coordinates: { lat: 55.9502, lng: -3.1885 } },
      { name: "Holyrood Palace", coordinates: { lat: 55.9532, lng: -3.1711 } },
    ],
  },
];

const StreetViewGame = () => {
  // State Management : State Variables
  const [gameState, setGameState] = useState("START");
  const [currentPosition, setCurrentPosition] = useState(null);
  const [destination, setDestination] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [radius, setRadius] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [scoreSaved, setScoreSaved] = useState(false);
  const [scoreSaveError, setScoreSaveError] = useState(null);
  const [wrongGuesses, setWrongGuesses] = useState(0);
  const [difficulty, setDifficulty] = useState(1); // Start at default difficulty level of 1
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState(null); // "correct", "wrong", or null

  // Refs
  const latestPositionRef = useRef(null);
  // Ref for the bird-eye map and marker
  const birdEyeMapRef = useRef(null);
  const userMarkerRef = useRef(null);
  const panoramaRef = useRef(null);

  const navigate = useNavigate();

  // ---------------- Utility Functions ----------------

  const getRandomDestinationLandmark = () => {
    const randomCity = CITIES[Math.floor(Math.random() * CITIES.length)];
    return randomCity.landmarks[
      Math.floor(Math.random() * randomCity.landmarks.length)
    ].coordinates;
  };

  const getStreetViewImageUrl = (lat, lng, heading, pitch) => {
    const timestamp = Date.now();
    const STREETVIEW_API = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&fov=90&heading=${heading}&pitch=${pitch}&key=AIzaSyAozYMPHTXFSR9fInh4u33TSpEtJ67HkfU&cachebuster=${timestamp}`;
    return STREETVIEW_API;
  };

  const getDistanceBetweenPoints = (point1, point2) => {
    const R = 6371e3; // Earth radius in meters
    const lat1Rad = (point1.lat * Math.PI) / 180;
    const lat2Rad = (point2.lat * Math.PI) / 180;
    const deltaLat = ((point2.lat - point1.lat) * Math.PI) / 180;
    const deltaLng = ((point2.lng - point1.lng) * Math.PI) / 180;

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1Rad) *
        Math.cos(lat2Rad) *
        Math.sin(deltaLng / 2) *
        Math.sin(deltaLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters
    return distance;
  };

  const handleDialogClose = () => {
    setFeedbackDialogOpen(false);
    if (feedback === "correct") {
      endGame();
    }
    setFeedback(null);
  };

  const navigateToLeaderboard = () => {
    navigate("/leaderboard");
  };

  const startGame = async () => {
    setGameState("PLAYING");
    try {
      const destinationPoint = getRandomDestinationLandmark();
      const startingPoint = await getRandomPointWithinRadius(
        destinationPoint,
        500
      );
      setCurrentPosition(startingPoint);
      setDestination(destinationPoint);
      setStartTime(Date.now());
    } catch (error) {
      console.error(error);
      alert("Couldn't find a suitable starting point. Please try again.");
    }
  };

  const checkDestination = () => {
    const currentPositionFromRef = latestPositionRef.current || currentPosition;
    const distance = getDistanceBetweenPoints(
      currentPositionFromRef,
      destination
    );
    setRadius(distance); // Set the current distance as the radius

    if (distance < ACCEPTABLE_DISTANCE_THRESHOLD) {
      setFeedback("correct"); // Setting feedback to "correct"
      setFeedbackDialogOpen(true);
    } else {
      setFeedback("wrong"); // Setting feedback to "wrong"
      setFeedbackDialogOpen(true);
      setWrongGuesses((prev) => prev + 1); // Increase wrong guess count
    }
  };

  async function getRandomPointWithinRadius(
    destination,
    maxDistanceInMeters,
    maxAttempts = 5
  ) {
    const R = 6371e3; // Earth's radius in meters

    for (let i = 0; i < maxAttempts; i++) {
      const randomDistance = Math.random() * maxDistanceInMeters;
      const randomAngle = Math.random() * 2 * Math.PI;

      const deltaLat = (randomDistance / R) * (180 / Math.PI);
      const deltaLng =
        ((randomDistance / R) * (180 / Math.PI)) /
        Math.cos((destination.lat * Math.PI) / 180);

      const potentialPoint = {
        lat: destination.lat + deltaLat * Math.cos(randomAngle),
        lng: destination.lng + deltaLng * Math.sin(randomAngle),
      };

      // Check if this point has navigable street view data
      const isNavigable = await checkStreetViewAvailability(
        potentialPoint.lat,
        potentialPoint.lng
      );
      if (isNavigable) {
        return potentialPoint;
      }
    }

    throw new Error(
      "Failed to find a navigable point after multiple attempts."
    );
  }

  const saveUserScore = async (
    endTime,
    startTime,
    wrongGuesses,
    difficulty
  ) => {
    const scoreValue = getScore(endTime, startTime, wrongGuesses, difficulty);

    if (scoreValue === undefined) {
      console.error("Score could not be calculated. Please check the inputs.");
      return;
    }

    const token = localStorage.getItem("token"); // Assuming you store the token in localStorage after login

    if (!token) {
      console.error("JWT not found in local storage. Please login again.");
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/scores`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token, // Include JWT token here
        },
        body: JSON.stringify({ score: scoreValue }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Unknown error");
      }

      if (response.status === 201) {
        setScoreSaved(true);
      } else {
        setScoreSaveError("Failed to save score. Please try again.");
      }
    } catch (error) {
      setScoreSaveError("Failed to save score. Please try again.");
    }
  };

  const endGame = async () => {
    setGameState("ENDED");
    const endTimeStamp = Date.now();
    setEndTime(endTimeStamp);

    // Save the user score
    await saveUserScore(endTimeStamp, startTime, wrongGuesses, difficulty);
  };

  const getScore = (endTime, startTime, wrongGuesses, difficulty) => {
    if (endTime && startTime) {
      const baseScore =
        (MAX_TIME - (endTime - startTime) / 1000) * SCORE_MULTIPLIER;
      const penalty = wrongGuesses * PENALTY_MULTIPLIER;
      return Math.max(0, (baseScore - penalty) * difficulty); // Ensure the score doesn't go negative
    }
    return undefined;
  };

  // Helper function to format the time
  const formatTime = (seconds) => {
    if (seconds < 60) return `${seconds} seconds`;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minutes ${remainingSeconds} seconds`;
  };

  const checkStreetViewAvailability = (lat, lng) => {
    return new Promise((resolve, reject) => {
      const svService = new window.google.maps.StreetViewService();
      svService.getPanoramaByLocation({ lat, lng }, 100, (data, status) => {
        if (
          status === window.google.maps.StreetViewStatus.OK &&
          data &&
          data.links &&
          data.links.length > 0
        ) {
          // Ensure there are navigational links available (meaning user can navigate from this point)
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  const initOrUpdateBirdEyeMap = (position) => {
    if (!birdEyeMapRef.current) {
      birdEyeMapRef.current = new window.google.maps.Map(
        document.getElementById("bird-eye-map"),
        {
          center: position,
          zoom: 30,
        }
      );
      userMarkerRef.current = new window.google.maps.Marker({
        position: position,
        map: birdEyeMapRef.current,
      });
    } else {
      userMarkerRef.current.setPosition(position);
      birdEyeMapRef.current.setCenter(position);
    }
  };
  const createBlinkingCircleMarker = (mapInstance, position) => {
    // Blue circle icon
    const circleIcon = {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: '#33b4ff',  // Light blue color
      fillOpacity: 1,
      scale: 6,
      strokeColor: 'white',  // White outline
      strokeWeight: 2
  };

    const marker = new window.google.maps.Marker({
        position: position,
        map: mapInstance,
        icon: circleIcon,
        title: "Your Current Position", // Optional
    });

    // // Blinking mechanism
    // let isVisible = true;
    // setInterval(() => {
    //     isVisible = !isVisible;
    //     marker.setVisible(isVisible);
    // }, 10000);

    return marker;
};

const initMap = useCallback(
    (position) => {
        if (!position || !window.google) return;

        // Initialize the bird's eye map
        birdEyeMapRef.current = new window.google.maps.Map(
            document.getElementById("bird-eye-map"),
            {
                zoom: 16,
                center: position,
            }
        );

        // Add a blinking blue circle marker for the user's position
        userMarkerRef.current = createBlinkingCircleMarker(birdEyeMapRef.current, position);

        // Initialize StreetView
        if (!panoramaRef.current) {
            panoramaRef.current = new window.google.maps.StreetViewPanorama(
                document.getElementById("street-view"),
                {
                    position: position,
                    pov: { heading: 165, pitch: 0 },
                    zoom: 1,
                }
            );
            panoramaRef.current.addListener("position_changed", () => {
                const newCurrentPosition = panoramaRef.current.getPosition().toJSON();
                latestPositionRef.current = newCurrentPosition;
                // Use your `initOrUpdateBirdEyeMap` or similar logic if needed
                initOrUpdateBirdEyeMap(newCurrentPosition);

                const distance = getDistanceBetweenPoints(
                    newCurrentPosition,
                    destination
                );
                setRadius(distance);
            });
        } else {
            panoramaRef.current.setPosition(position);
        }
    },
    [destination]
);

  // Timer useEffect
  useEffect(() => {
    if (gameState === "PLAYING" && startTime) {
      const interval = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
      return () => clearInterval(interval); // Cleanup on component unmount or state change
    }
  }, [gameState, startTime, initMap]);

  // Load Google Maps API script if not already loaded and then initialize map
  useEffect(() => {
    if (window.google) {
      if (gameState === "PLAYING" && currentPosition) {
        initMap(currentPosition);
      }
    } else {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAozYMPHTXFSR9fInh4u33TSpEtJ67HkfU";
      script.async = true;
      script.defer = true;
      script.addEventListener("load", () => {
        if (gameState === "PLAYING" && currentPosition) {
          initMap(currentPosition);
        }
      });
      document.body.appendChild(script);
    }
  }, [gameState, currentPosition, initMap]);

  useEffect(() => {
    if (gameState === "ENDED" && !scoreSaved && !scoreSaveError) {
      saveUserScore(endTime, startTime, wrongGuesses, difficulty);
    }
  }, [gameState, endTime, startTime, scoreSaved, scoreSaveError]);

  // --------------- Render Component ---------------

  return (
    <div>
      {gameState === "START" && (
        <div className="start-screen-container">
          <SidebarNavigation />
          <GameTitle className="game-title">Street View Challenge</GameTitle>
          <GameText>
            Use the map below to navigate the streets and find the mystery
            destination. When you think you're at the correct location, press
            "Guess Location".
          </GameText>

          <RoundedButton onClick={startGame}>Start Game</RoundedButton>
        </div>
      )}

      {gameState === "PLAYING" && (
        <>
          <Dialog
            open={feedbackDialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {feedback === "correct" && (
              <>
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ textAlign: "center" }}
                >
                  {"Congratulations!"}
                </DialogTitle>
                <DialogContent>
                  <img
                    src={CorrectGuessIllustration}
                    alt="Correct Guess"
                    style={{ width: "100%" }}
                  />
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ textAlign: "center" }}
                  >
                    You've correctly identified the location!
                  </DialogContentText>
                </DialogContent>
              </>
            )}
            {feedback === "wrong" && (
              <>
                <DialogTitle
                  id="alert-dialog-title"
                  style={{ textAlign: "center" }}
                >
                  {"Oops!"}
                </DialogTitle>
                <DialogContent>
                  <img
                    src={WrongGuessIllustration}
                    alt="Wrong Guess"
                    style={{ width: "100%" }}
                  />
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ textAlign: "center" }}
                  >
                    That's not the right location. Keep trying!
                  </DialogContentText>
                </DialogContent>
              </>
            )}
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                Okay
              </Button>
            </DialogActions>
          </Dialog>

          {/* Timer display - updated to use styled-components */}
          <TimerWrapper>
            <Title>Time: {formatTime(elapsedTime)}</Title>
            {radius && <Score>Distance: {radius.toFixed(2)} meters</Score>}
          </TimerWrapper>

          <div
            id="bird-eye-map"
            style={{
              position: "fixed",
              bottom: "10px",
              left: "10px",
              width: "300px",
              height: "200px",
              zIndex: 1003,
              border: "2px solid black",
            }}
          ></div>

          {/* Street View component to take up entire screen */}
          <div
            id="street-view"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              zIndex: 12,
            }}
          ></div>

          <RoundedButton
            onClick={checkDestination}
            sx={{
              position: "fixed",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1001,
            }}
          >
            I think I'm here!
          </RoundedButton>

          {/* Show destination image at the bottom right */}
          {destination && (
            <div
              style={{
                position: "fixed",
                bottom: "10px",
                left: "10px",
                zIndex: 1002,
              }}
            >
              <img
                src={getStreetViewImageUrl(
                  destination.lat,
                  destination.lng,
                  heading,
                  pitch
                )}
                alt="Destination to find"
                style={{
                  width: "300px",
                  height: "200px",
                  border: "2px solid black",
                }}
              />
            </div>
          )}
        </>
      )}

{gameState === "ENDED" && (
    <EndScreenContainer>
        <StyledLeaderboardButton
            variant="text"
            startIcon={<LeaderboardIcon />}
            onClick={navigateToLeaderboard}
        >
            View Leaderboard
        </StyledLeaderboardButton>
        <SidebarNavigation />
        <GameTitle className="game-title">Game Over</GameTitle>
        <GameText>
            Well done! You've finished the challenge in <Highlight>{formatTime(elapsedTime)}</Highlight> with <Highlight>{wrongGuesses}</Highlight> wrong guesses.
        </GameText>
     
        <div className="score-message">
            {scoreSaved ? (
                <>
                    <GameText align="center">
                        Your score was successfully saved!
                    </GameText>
                    <ScoreDisplay>
                        <h2>
                            Score:{" "}
                            <Highlight>
                                {Math.round(
                                    getScore(endTime, startTime, wrongGuesses, difficulty)
                                )}
                            </Highlight>
                        </h2>
                    </ScoreDisplay>
                </>
            ) : (
                <>
                    <GameText>
                        Failed to save your score. Please try again later.
                    </GameText>
                    {scoreSaveError && (
                        <p className="error-message">Error: {scoreSaveError}</p>
                    )}
                </>
            )}
        </div>
        <RoundedButton onClick={startGame}>Play Again</RoundedButton>
    </EndScreenContainer>
      )}
    </div>
  );
};

export default StreetViewGame;
