import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, TextField, Button, Typography, IconButton, Divider } from '@mui/material';
import { Apple, Google } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import styled from 'styled-components';
import config from '../../config';

const WhiteDivider = styled(Divider)`
  background-color: grey;
`;

const Background = styled.div`
  height: 100vh;
  background: linear-gradient(45deg, #000000, #003366, #004d00);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoundedButton = styled(Button)`
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border: 0;
  border-radius: 25px !important;
  margin-top: 20px !important;
  padding: 10px 30px !important;
  color: white;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
`;

const StyledPaper = styled(Paper)`
  && {
    background: linear-gradient(120deg, #003366, #000000, #004d00);
    padding: 2em;
    width: 400px;
    border-radius: 60px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
  }
`;

function SignUp() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [username, setUsername] = useState('');

    const navigate = useNavigate();
    const { login } = useAuth();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (password !== confirmPassword) {
        console.error("Passwords don't match.");
        return;
      }
  
      try {
        const response = await fetch(`${config.API_URL}/signup`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, username }), // <-- include username here
        });
        const data = await response.json();
  
        if (response.status === 201) { 
          console.log(data.message);
          login(data.token); // pass the token to login
          navigate('/game');
        } else {
          console.error(data.error);
          setErrorMessage(data.error);  // Set the error message if signup failed
        }
      } catch (error) {
        console.error('There was an error signing up', error);
        setErrorMessage('There was an error signing up.');  // Handle fetch errors
      }
    };
    
  
    return (
      <Background>
        <StyledPaper>
          <Grid container direction="column" spacing={2}>
            {errorMessage && (
              <Grid item>
                <Typography color="error">{errorMessage}</Typography> {/* Show the error message */}
              </Grid>
            )}
            <Grid item>
              <TextField
                fullWidth
                type="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ style: { color: 'white' }, shrink: true, position: "static" }}
                inputProps={{ style: { color: 'white' } }}
                OutlinedInputProps={{
                  style: { borderColor: 'grey' },
                  notched: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputLabelProps={{ style: { color: 'white' }, shrink: true, position: "static" }}
                inputProps={{ style: { color: 'white' } }}
                OutlinedInputProps={{
                  style: { borderColor: 'grey' },
                  notched: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{ style: { color: 'white' }, shrink: true, position: "static" }}
                inputProps={{ style: { color: 'white' } }}
                OutlinedInputProps={{
                  style: { borderColor: 'grey' },
                  notched: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputLabelProps={{ style: { color: 'white' }, shrink: true, position: "static" }}
                inputProps={{ style: { color: 'white' } }}
                OutlinedInputProps={{
                  style: { borderColor: 'white' },
                  notched: true,
                }}
              />
            </Grid>
            <Grid item>
              <RoundedButton variant="contained" color="primary" fullWidth type="submit" onClick={handleSubmit}>
                Sign Up
              </RoundedButton>
            </Grid>
            <Grid item>
              <WhiteDivider />
            </Grid>
            <Grid item container justifyContent="center" spacing={1}>
              <Grid item>
                <Button onClick={() => navigate('/login')} style={{ color: 'white' }}>
                  Already have an account? Log In
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
      </Background>
    );
}

export default SignUp;