import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const StyledDrawer = styled(Drawer)`
  background: linear-gradient(to bottom right, darkblue, #50c878);
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// New styled component for the icon
const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
  position: absolute; // Absolute positioning
  top: 20px; // 20px from the top
  right: 20px; // 20px from the right
`;

function SidebarNavigation() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const handleGameNavigation = () => {
    navigate("/game");
    setIsSidebarOpen(false);
  };

  const handleProfileNavigation = () => {
    navigate("/profile");
    setIsSidebarOpen(false);
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${config.API_URL}/logout`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
      });
      if (response.ok) {
        localStorage.removeItem("token");
        setIsSidebarOpen(false);
        navigate("/login");
      } else {
        // Handle error - maybe show a message to the user
      }
    } catch (error) {
      console.error("Logout Error:", error.message);
    }
  };
  return (
    <div>
      <StyledMenuIcon onClick={() => setIsSidebarOpen(true)} />

      <StyledDrawer
        anchor="left"
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
      >
        <List>
          <ListItem button onClick={handleGameNavigation}>
            <ListItemIcon>
              <PlayCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Game" />
          </ListItem>
          <ListItem button onClick={handleProfileNavigation}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </StyledDrawer>
    </div>
  );
}

export default SidebarNavigation;
